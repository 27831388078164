import React from "react"

import Section from "elements/Section"
import FormCheckbox from "elements/Form/FormCheckbox"

const declarationOfInformation = (
  <span>
    I declare that the information I have supplied is true and accurate. I also
    authorize MedGrocer to make any inquiries necessary for the verification of
    the information contained in this form and for the purpose of the financial
    assessment.
  </span>
)

const ConsentAndAuthorization = ({ values }) => {
  return (
    <Section title="Declaration" isRequired>
      <div className="mx-2 mx-0-mobile">
        <FormCheckbox
          name="consentAndAuth"
          values={values.consentAndAuth}
          options={[declarationOfInformation]}
        />
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
