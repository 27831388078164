import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import styles from "../utils/financialAssessment.module.scss"

import { formatNumber } from "../../../services/general"

const MontlyExpenseAndLiabilitiesSection = ({ data }) => {
  let hasValues = true
  for (let amount in data?.monthlyLiabilitiesAndExpenses) {
    if (data?.monthlyLiabilitiesAndExpenses[amount].amount === "")
      hasValues = false
  }

  return (
    <Section
      title="Monthly Expenses and Liabilities"
      isRequired
      addOns={{
        right: (
          <EditDetailsButton route="/financial-assessment/financial-details#expenses-and-liabilities" />
        ),
      }}
    >
      {!hasValues && (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            No monthly expenses and liabilities found.
          </p>
        </Message>
      )}
      {hasValues && (
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              {data?.monthlyLiabilitiesAndExpenses?.map(expense => (
                <tr>
                  <td>{expense.name}</td>
                  <td
                    className={classNames(
                      "has-text-right",
                      styles["summary__tableData"]
                    )}
                  >
                    {expense?.otherName && `${expense?.otherName} - `}
                    {formatNumber(expense.amount.replaceAll(",", ""))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}

export default MontlyExpenseAndLiabilitiesSection
