import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import styles from "../utils/financialAssessment.module.scss"

const IncomeInformationSection = ({ data }) => {
  let {
    sourceOfIncome,
    patientMonthlyIncome,
    monthlyBusinessIncome,
    monthlyIncome,
    monthlyAverage,
    propertyMonthlyIncome,
    estateMonthlyIncome,
    investmentMonthlyAverage,
    monthlyPension,
    monthlyRemittance,
    monthlySupport,
    otherSource,
    monthlyAmount,
  } = data

  let sourcesOfIncome = []
  sourceOfIncome.includes("Salary or wage") &&
    sourcesOfIncome.push(`Salary or wage: ${patientMonthlyIncome}`)
  sourceOfIncome.includes("Business") &&
    sourcesOfIncome.push(`Business: ${monthlyBusinessIncome}`)
  sourceOfIncome.includes("Practice of profession") &&
    sourcesOfIncome.push(`Practice of profession: ${monthlyIncome}`)
  sourceOfIncome.includes("Commissions") &&
    sourcesOfIncome.push(`Commissions: ${monthlyAverage}`)
  sourceOfIncome.includes("Farms, haciendas, or fish ponds") &&
    sourcesOfIncome.push(
      `Farms, haciendas, or fish ponds: ${propertyMonthlyIncome}`
    )
  sourceOfIncome.includes("Real estate, house, or room rentals") &&
    sourcesOfIncome.push(
      `Real estate, house, or room rentals: ${estateMonthlyIncome}`
    )
  sourceOfIncome.includes("Earnings from investments") &&
    sourcesOfIncome.push(
      `Earnings from investments: ${investmentMonthlyAverage}`
    )
  sourceOfIncome.includes("Pensions") &&
    sourcesOfIncome.push(`Pension: ${monthlyPension}`)
  sourceOfIncome.includes("Remittance") &&
    sourcesOfIncome.push(`Remittance: ${monthlyRemittance}`)
  sourceOfIncome.includes("Family support") &&
    sourcesOfIncome.push(`Family support: ${monthlySupport}`)
  sourceOfIncome.includes("Other (Please specify)") &&
    sourcesOfIncome.push(
      `Other (Please specify): ${otherSource} - ${monthlyAmount}`
    )

  let checklist = [data.patientEmploymentStatus.value, sourcesOfIncome]

  let hasValue = true
  for (let condition in checklist) {
    if (!checklist[condition]) hasValue = false
  }

  return (
    <Section
      title="Income Information"
      isRequired
      addOns={{
        right: (
          <EditDetailsButton route="/financial-assessment/financial-details" />
        ),
      }}
    >
      {!hasValue && (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            No income information found.
          </p>
        </Message>
      )}
      {hasValue && (
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              <tr>
                <td>Patient’s Employment Status</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.patientEmploymentStatus.value}
                </td>
              </tr>
              <tr>
                <td>Source of Income or Funds (monthly)</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {sourcesOfIncome.join(", ")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}

export default IncomeInformationSection
