import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"

export const patientDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  email: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    province: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    city: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
  yearsOfResidency: Yup.number()
    .max(100, "Years of residency must be less than or equal to 100")
    .required(REQUIRED_MESSAGE),
  civilStatus: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  numberOfDependents: Yup.number().required(REQUIRED_MESSAGE),
})

export const financialDetailsSchema = Yup.object().shape({
  patientEmploymentStatus: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),

  sourceOfIncome: Yup.array().min(1, REQUIRED_MESSAGE),
  patientMonthlyIncome: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Salary or wage"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyBusinessIncome: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Business"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyIncome: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Practice of profession"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyAverage: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Commissions"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  propertyMonthlyIncome: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome =>
      sourceOfIncome.includes("Farms, haciendas, or fish ponds"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  estateMonthlyIncome: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome =>
      sourceOfIncome.includes("Real estate, house, or room rentals"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  investmentMonthlyAverage: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Earnings from investments"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  sourceOfPension: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Pensions"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyPension: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Pensions"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  sourceOfRemittance: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Remittance"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyRemittance: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Remittance"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  sourceOfSupport: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Family support"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlySupport: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Family support"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  otherSource: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Other (Please specify)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyAmount: Yup.string().when("sourceOfIncome", {
    is: sourceOfIncome => sourceOfIncome.includes("Other (Please specify)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),

  publicAmountPhilHealth: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport => publicFundingSupport.includes("PhilHealth"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountDSWD: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes(
        "Department of Social Welfare and Development (DSWD)"
      ),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountDOH: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes("Department of Health (DOH)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountPAGCOR: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes(
        "Philippine Amusement and Gaming Corporation (PAGCOR)"
      ),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountPCSO: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes(
        "Philippine Charity Sweepstakes Office (PCSO)"
      ),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountOWWA: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes(
        "Overseas Workers Welfare Administration (OWWA)"
      ),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountOfficeOfThePresident: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes("Office of the President"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountOfficeOfTheMayorRepresentative: Yup.string().when(
    "publicFundingSupport",
    {
      is: publicFundingSupport =>
        publicFundingSupport.includes(
          "Office of the Mayor or Congressional Representative"
        ),
      then: Yup.string().required(REQUIRED_MESSAGE),
    }
  ),
  publicAmountOfficeOfTheVicePresident: Yup.string().when(
    "publicFundingSupport",
    {
      is: publicFundingSupport =>
        publicFundingSupport.includes("Office of the Vice President"),
      then: Yup.string().required(REQUIRED_MESSAGE),
    }
  ),
  publicAmountOtherName: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes("Other (please specify)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountOtherAmount: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes("Other (please specify)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  publicAmountSSSGSISLoan: Yup.string().when("publicFundingSupport", {
    is: publicFundingSupport =>
      publicFundingSupport.includes("SSS or GSIS loan"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),

  privateAmountCompanyHealthBenefit: Yup.string().when(
    "privateFundingSupport",
    {
      is: privateFundingSupport =>
        privateFundingSupport.includes("Company health benefit"),
      then: Yup.string().required(REQUIRED_MESSAGE),
    }
  ),
  privateAmountPersonalHMO: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport => privateFundingSupport.includes("Personal HMO"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privatePersonalHMOProvider: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport => privateFundingSupport.includes("Personal HMO"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privateAmountPersonalInsurance: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport =>
      privateFundingSupport.includes("Personal insurance"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privatePersonalInsuranceProvider: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport =>
      privateFundingSupport.includes("Personal insurance"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privateAmountFoundationsNGOs: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport =>
      privateFundingSupport.includes("Foundations or NGOs"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privateFoundationsNGOsProvider: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport =>
      privateFundingSupport.includes("Foundations or NGOs"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privateAmountPersonalBankLoansCredits: Yup.string().when(
    "privateFundingSupport",
    {
      is: privateFundingSupport =>
        privateFundingSupport.includes("Personal bank loans or credits"),
      then: Yup.string().required(REQUIRED_MESSAGE),
    }
  ),
  privateAmountOtherName: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport =>
      privateFundingSupport.includes("Other (please specify)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  privateAmountOtherAmount: Yup.string().when("privateFundingSupport", {
    is: privateFundingSupport =>
      privateFundingSupport.includes("Other (please specify)"),
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),

  // Type of residence validation
  typeOfResidence: Yup.string().required(REQUIRED_MESSAGE),
  mortgagedMonthlyAmortization: Yup.string().when("typeOfResidence", {
    is: "Owned, mortgaged",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  monthlyRental: Yup.string().when("typeOfResidence", {
    is: "Rented",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  otherResidenceStatus: Yup.string().when("typeOfResidence", {
    is: "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  sizeOfResidence: Yup.number()
    .max(5000, "Floor area must be less than or equal to 5000")
    .required(REQUIRED_MESSAGE),
  hasVehicle: Yup.string().required(REQUIRED_MESSAGE),
  numberOfVehiclesOwned: Yup.number().when("hasVehicle", {
    is: "Yes",
    then: Yup.number()
      .max(10, "Number of vehicles must be less than or equal to 10")
      .required(REQUIRED_MESSAGE),
  }),
  kindsOfVehicleOwned: Yup.string().when("hasVehicle", {
    is: "Yes",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),

  monthlyLiabilitiesAndExpenses: Yup.array().of(
    Yup.object().shape({
      otherName: Yup.string().when("name", {
        is: name => name === "Other utility bills (please specify)",
        then: Yup.string().required(REQUIRED_MESSAGE),
      }),
      amount: Yup.string().required(REQUIRED_MESSAGE),
    })
  ),
})

export const consentAndAuthValidation = Yup.object().shape({
  consentAndAuth: Yup.array().min(2, "This field is required"),
})
