import React from "react"
import { Router } from "@reach/router"

import PatientDetails from "../components/FinancialAssessment/PatientDetails"
import UploadDocuments from "../components/FinancialAssessment/UploadDocuments"
import FinancialAssessment from "../components/FinancialAssessment/FinancialAssessment"
import SummaryPage from "../components/FinancialAssessment/FinancialAssessmentSummaryPage"
import FinancialAssessmentComplete from "../components/FinancialAssessment/FinancialAssessmentComplete"
import FinancialDetails from "../components/FinancialAssessment/FinancialAssessmentFinancialDetails"

export default () => {
  return (
    <Router basepath="/financial-assessment">
      <SummaryPage path="/summary" />
      <FinancialAssessment path="/" />
      <UploadDocuments path="/upload" />
      <PatientDetails path="/patient-details" />
      <FinancialAssessmentComplete path="/complete" />
      <FinancialDetails path="/financial-details" />
    </Router>
  )
}
