import React, { useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Message from "../Elements/Message"
import Section from "../Elements/Section"
import Container from "../Layout/Container"
import ActionButtons from "../Elements/ActionButtons"

import { AppContext } from "../../context/AppContext"
import { generateFormField } from "../Elements/Form/services/form"
import { patientDetailsForm } from "./utils/financialAssessmentForm"
import { patientDetailsSchema } from "./utils/financialAssessmentValidation"
import { patientDetails } from "../../context/AppReducer"

import {
  GATSBY_FINANCIAL_ASSESSMENT_KEY,
  GATSBY_FINANCIAL_ASSESSMENT_VALUE,
} from "gatsby-env-variables"

const FinancialAssessment = () => {
  const { state, dispatch } = useContext(AppContext)
  let patientDetailsInitalState = state.patientDetails

  const handleSubmit = values => {
    dispatch({
      type: patientDetails.SAVE_PATIENT_DETAILS,
      payload: { ...values },
    })
    navigate("/financial-assessment/financial-details")
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_FINANCIAL_ASSESSMENT_KEY) !==
      GATSBY_FINANCIAL_ASSESSMENT_VALUE
    ) {
      navigate("/financial-assessment/")
    }
  }, [])

  return (
    <Layout
      title="Financial Assessment Form"
      seoTitle="Financial Assessment"
      subtitle="Please ensure that the information provided below is correct."
    >
      <Container isCentered>
        <Formik
          validationSchema={patientDetailsSchema}
          initialValues={patientDetailsInitalState}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              <Section title="Patient Details" isRequired>
                {patientDetailsForm.map(field => {
                  return generateFormField({
                    formFields: patientDetailsForm,
                    formField: field,
                    values,
                    setFieldValue,
                  })
                })}
              </Section>
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                back={{ label: "Back", link: "/financial-assessment" }}
                submit={{ label: "Next" }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default FinancialAssessment
