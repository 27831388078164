import {
  MONEY_PLACEHOLDER,
  hasVehicleFollowupQuestions,
  incomeInformationFollowUpQuestions,
  housingResidenceStatusFollowupQuestions,
  privateFundingSupportAmountsFollowupQuestions,
  publicAlternativeFundingSupportFollowupQuestions,
} from "./financialAssessmentFollowUpQuestions"

export const patientDetailsForm = [
  {
    type: "text",
    name: "firstName",
    label: "First Name",
    placeholder: "Juan",
    isRequired: true,
  },
  {
    type: "text",
    name: "lastName",
    label: "Last Name",
    placeholder: "Dela Cruz",
    isRequired: true,
  },
  {
    type: "text",
    name: "middleInitial",
    label: "Middle Initial",
    placeholder: "A",
    isRequired: false,
  },
  {
    type: "text",
    name: "email",
    label: "Email",
    placeholder: "jdelacruz@gmail.com",
    isRequired: true,
  },
  {
    type: "text",
    name: "mobileNumber",
    label: "Mobile Number",
    placeholder: "09991234567",
    maxLength: 11,
    isRequired: true,
  },
  {
    type: "address",
    isRequired: true,
    isNationwide: true,
    valueType: "deliveryAddress",
    fieldNames: {
      streetAddress: "deliveryAddress.streetAddress",
      city: "deliveryAddress.city",
      province: "deliveryAddress.province",
    },
  },
  {
    type: "number",
    name: "yearsOfResidency",
    label: "Years of Residency",
    placeholder: "1",
    isRequired: true,
    min: "0",
    max: 100,
  },
  {
    type: "select",
    name: "civilStatus",
    label: "Civil Status",
    placeholder: "Single",
    options: [
      { value: "Single", label: "Single" },
      { value: "Single parent", label: "Single parent" },
      { value: "Married", label: "Married" },
      { value: "Separated or divorced", label: "Separated or divorced" },
      { value: "Widowed", label: "Widowed" },
      { value: "Living with partner", label: "Living with partner" },
    ],
    isRequired: true,
  },
  {
    type: "number",
    name: "numberOfDependents",
    label: "Number of Dependents",
    placeholder: "1",
    helper:
      "Dependents include spouse and children 21 years old and below (for parents) and siblings and parents above 60 years old (for single persons).",
    isRequired: true,
    min: "0",
    maxLength: "2",
  },
  {
    type: "number",
    name: "philHealthNumber",
    label: "PhilHealth Number",
    placeholder: "112015344047",
    isRequired: false,
    maxLength: "12",
    min: 0,
  },
  {
    type: "number",
    name: "tinNumber",
    label: "TIN Number",
    placeholder: "123456789000",
    isRequired: false,
    maxLength: "12",
    min: 0,
  },
  {
    type: "number",
    name: "sssGSISNumber",
    label: "SSS or GSIS Number",
    placeholder: "3485073113",
    isRequired: false,
    maxLength: 10,
    min: 0,
  },
]

export const financialDetailsForm = [
  {
    section: "Income Information",
    id: "income-information",
    subtitle: "How are you paying for your care?",
    isRequired: true,
    fields: [
      {
        type: "select",
        name: "patientEmploymentStatus",
        label: "Patient’s Employment Status",
        placeholder: "Employed (Public)",
        options: [
          { value: "Employed (Public)", label: "Employed (Public)" },
          { value: "Employed (Private)", label: "Employed (Private)" },
          { value: "Self-employed", label: "Self-employed" },
          { value: "Retired", label: "Retired" },
          { value: "Unemployed", label: "Unemployed" },
          { value: "Student", label: "Student" },
        ],
        isRequired: true,
      },
      {
        type: "checkbox",
        options: [
          "Salary or wage",
          "Business",
          "Practice of profession",
          "Commissions",
          "Farms, haciendas, or fish ponds",
          "Real estate, house, or room rentals",
          "Earnings from investments",
          "Pensions",
          "Remittance",
          "Family support",
          "Other (Please specify)",
        ],
        isRequired: true,
        label: "Source of Income or Funds",
        name: "sourceOfIncome",
        followUpQuestions: [
          "patientMonthlyIncome",
          "householdMonthlyIncome",
          "monthlyBusinessIncome",
          "monthlyIncome",
          "monthlyAverage",
          "propertyMonthlyIncome",
          "estateMonthlyIncome",
          "investmentMonthlyAverage",
          "sourceOfPension",
          "monthlyPension",
          "sourceOfRemittance",
          "monthlyRemittance",
          "sourceOfSupport",
          "monthlySupport",
          "otherSource",
          "monthlyAmount",
        ],
        isFollowUpQuestion: true,
      },
      ...incomeInformationFollowUpQuestions,
    ],
  },
  {
    section: "Alternative Funding Support or Financial Assistance Availed",
    id: "funding-support",
    subtitle: "Where do you get alternative funding for your treatment?",
    isRequired: true,
    fields: [
      {
        type: "checkbox",
        name: "publicFundingSupport",
        supportingName: "publicFundingSupportDetails",
        hasSupportingValues: true,
        supportingDetailsKey: "amount",
        options: [
          "PhilHealth",
          "Department of Social Welfare and Development (DSWD)",
          "Department of Health (DOH)",
          "Philippine Amusement and Gaming Corporation (PAGCOR)",
          "Philippine Charity Sweepstakes Office (PCSO)",
          "Overseas Workers Welfare Administration (OWWA)",
          "Office of the President",
          "Office of the Vice President",
          "Office of the Mayor or Congressional Representative",
          "SSS or GSIS loan",
          "Other (please specify)",
          "You have no public or government funding sources",
        ],
        isRequired: true,
        label: "Public or government funding sources",
        helper:
          "Please provide supporting documents for the public or government funding sources that apply.",
        followUpQuestions: [
          "publicAmountPhilHealth",
          "publicAmountDSWD",
          "publicAmountDOH",
          "publicAmountPAGCOR",
          "publicAmountPCSO",
          "publicAmountOWWA",
          "publicAmountOfficeOfThePresident",
          "publicAmountOfficeOfTheVicePresident",
          "publicAmountOfficeOfTheMayorRepresentative",
          "publicAmountSSSGSISLoan",
          "publicAmountOtherName",
          "publicAmountOtherAmount",
        ],
        hasFollowupQuestion: true,
        isFollowUpQuestion: true,
      },
      {
        type: "checkbox",
        name: "privateFundingSupport",
        hasSupportingValues: true,
        supportingName: "privateFundingSupportDetails",
        supportingDetailsKey: "amount",
        options: [
          "Company health benefit",
          "Personal HMO",
          "Personal insurance",
          "Foundations or NGOs",
          "Personal bank loans or credits",
          "Other (please specify)",
          "You have no private funding sources",
        ],
        isRequired: true,
        label: "Private funding sources",
        helper:
          "Please provide supporting documents for the private funding sources that apply.",
        followUpQuestions: [
          "privateAmountCompanyHealthBenefit",
          "privatePersonalHMOProvider",
          "privateAmountPersonalHMO",
          "privatePersonalInsuranceProvider",
          "privateAmountPersonalInsurance",
          "privateFoundationsNGOsProvider",
          "privateAmountFoundationsNGOs",
          "privateAmountPersonalBankLoansCredits",
          "privateAmountOtherName",
          "privateAmountOtherAmount",
        ],
        isFollowUpQuestion: true,
      },
      ...publicAlternativeFundingSupportFollowupQuestions,
      ...privateFundingSupportAmountsFollowupQuestions,
    ],
  },
  {
    section: "Assets",
    id: "assets",
    isRequired: true,
    fields: [
      {
        type: "radio",
        options: [
          "Owned, not mortgaged",
          "Owned, mortgaged",
          "Rent free (e.g. living with relatives)",
          "Rented",
          "Other (please specify)",
        ],
        isRequired: true,
        label: "Residence type",
        name: "typeOfResidence",
        followUpQuestions: [
          "monthlyAmortization",
          "mortgagedMonthlyAmortization",
          "monthlyRentalWithRelatives",
          "monthlyRental",
          "otherResidenceStatus",
          {
            type: "text",
            isRequired: true,
            label: "How many vehicles do you own?",
            placeholder: "1",
            name: "numberOfVehiclesOwned",
            referenceQuestion: "hasVehicle",
            referenceAnswer: "yes",
            isFollowUpQuestion: true,
          },
          {
            type: "text",
            isRequired: true,
            label: "What kinds of vehicles do you own? (e.g. Honda Jazz)",
            name: "kindsOfVehicleOwned",
            referenceQuestion: "hasVehicle",
            referenceAnswer: "yes",
            isFollowUpQuestion: true,
          },
          "otherResidenceStatusMonthly",
        ],
        isFollowUpQuestion: true,
      },
      ...housingResidenceStatusFollowupQuestions,
      {
        type: "number",
        name: "sizeOfResidence",
        label:
          "What is the approximate floor area (in square meters) of the house you are staying in?",
        isRequired: true,
        placeholder: "200",
        min: "1",
        max: 5000,
      },
      {
        type: "radio",
        options: ["Yes", "No"],
        isRequired: true,
        label: "Do you own any vehicles?",
        name: "hasVehicle",
        followUpQuestions: ["numberOfVehiclesOwned", "kindsOfVehicleOwned"],
        isFollowUpQuestion: true,
      },
      ...hasVehicleFollowupQuestions,
    ],
  },
  {
    section: "Monthly Expenses and Liabilities",
    id: "expenses-and-liabilities",
    subtitle:
      "Please indicate the average monthly cost for each expenditure below.",
    isRequired: true,
    fields: [
      {
        type: "fieldarray",
        labelsHeader: "Expenses or Liabilities",
        inputsHeader: "Average Monthly Cost",
        name: "monthlyLiabilitiesAndExpenses",
        supportingDetailKey: "amount",
        inputProps: {
          type: "text",
          isRequired: true,
          placeholder: MONEY_PLACEHOLDER,
          min: "0",
          maxLength: "11",
          isNumeric: true,
        },
        otherInputProps: {
          type: "text",
          isRequired: true,
          placeholder: "Other utility bill",
        },
        fields: [
          "Food",
          "Education",
          "Transportation",
          "Groceries",
          "Clothing and footwear",
          "Electricity",
          "Water",
          "Internet",
          "Cable subscription",
          "Telecommunication",
          "Other utility bills (please specify)",
          "Household personnel working for the family (e.g. housemaid, driver, cook)",
          "Interest for loans or credits",
          "Insurance premium",
        ],
      },
    ],
  },
]
