import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import styles from "../utils/financialAssessment.module.scss"

const AssetsSection = ({ data }) => {
  let { typeOfResidence, sizeOfResidence, hasVehicle } = data

  let checklist = [typeOfResidence, sizeOfResidence, hasVehicle]

  let hasValue = true
  for (let condition in checklist) {
    if (!checklist[condition]) hasValue = false
  }

  return (
    <Section
      title="Assets"
      isRequired
      addOns={{
        right: (
          <EditDetailsButton route="/financial-assessment/financial-details#assets" />
        ),
      }}
    >
      {!hasValue && (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            No asset information found.
          </p>
        </Message>
      )}
      {hasValue && (
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              <tr>
                <td>Type of residence</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.typeOfResidence}
                </td>
              </tr>
              <tr>
                <td>Size of residence</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.sizeOfResidence}
                </td>
              </tr>
              <tr>
                <td>Vehicles owned</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.hasVehicle}{" "}
                  {data.hasVehicle === "Yes" &&
                    `(${data.numberOfVehiclesOwned} - ${data.kindsOfVehicleOwned})`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}

export default AssetsSection
