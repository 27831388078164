import React, { useContext } from "react"

import Section from "elements/Section"
import Message from "elements/Message"
import FileThumbnail from "elements/Dropzone/FileThumbnail"

import { AppContext } from "../../../context/AppContext"

const PrescriptionDetailsSection = () => {
  const { state } = useContext(AppContext)

  if (state?.documents?.length > 0)
    return (
      <Section title="Documents Uploaded" isRequired={true}>
        <div className="px-1 mx-1">
          <div className="columns is-vcentered is-multiline">
            {state?.documents.map(document => (
              <div className="column is-6">
                <FileThumbnail file={document} hasFileName />
              </div>
            ))}
          </div>
        </div>
      </Section>
    )
  return (
    <Section title="Documents Uploaded" isRequired={true}>
      <Message color="warning">
        <p className="has-text-black is-size-6">
          You have not uploaded any documents.
        </p>
      </Message>
    </Section>
  )
}

export default PrescriptionDetailsSection
