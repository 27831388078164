import React, { useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Message from "../Elements/Message"
import Section from "../Elements/Section"
import Container from "../Layout/Container"
import ActionButtons from "../Elements/ActionButtons"

import { AppContext } from "../../context/AppContext"
import { financialDetails } from "../../context/AppReducer"
import { generateFormField } from "../Elements/Form/services/form"
import { financialDetailsForm } from "./utils/financialAssessmentForm"
import { financialDetailsSchema } from "./utils/financialAssessmentValidation"

import {
  GATSBY_FINANCIAL_ASSESSMENT_KEY,
  GATSBY_FINANCIAL_ASSESSMENT_VALUE,
} from "gatsby-env-variables"

const FinancialAssessment = () => {
  const { state, dispatch } = useContext(AppContext)
  let financialDetailsInitialState = state.financialDetails

  const handleSubmit = async values => {
    await dispatch({
      type: financialDetails.SAVE_FINANCIAL_DETAILS,
      payload: { ...values },
    })
    navigate("/financial-assessment/upload")
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_FINANCIAL_ASSESSMENT_KEY) !==
      GATSBY_FINANCIAL_ASSESSMENT_VALUE
    ) {
      navigate("/financial-assessment/")
    }
  }, [])

  return (
    <Layout
      title="Financial Assessment Form"
      subtitle="Please ensure that the information provided below is correct."
    >
      <SEO title="Financial Assessment" />
      <Container isCentered>
        <Formik
          validationSchema={financialDetailsSchema}
          initialValues={financialDetailsInitialState}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {financialDetailsForm.map(section => (
                <Section
                  id={section?.id}
                  title={section?.section}
                  isRequired={section?.isRequired}
                  subtitle={section?.subtitle}
                >
                  {section?.fields.map(field => {
                    if (!field?.referenceAnswer) {
                      return generateFormField({
                        formFields: section?.fields,
                        formField: field,
                        values,
                        setFieldValue,
                      })
                    }
                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                back={{
                  label: "Back",
                  link: "/financial-assessment/patient-details",
                }}
                submit={{ label: "Next" }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default FinancialAssessment
