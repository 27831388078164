import React from "react"

import Message from "elements/Message"

const RequirementsMessage = ({ header, requirements }) => {
  return (
    <Message color="light">
      {header && <p className="is-size-6 has-text-weight-bold">{header}</p>}
      <p className="content is-size-6">
        <ul>
          {requirements.map(requirement => (
            <li className="pb-0">{requirement}</li>
          ))}
        </ul>
      </p>
    </Message>
  )
}

export default RequirementsMessage
