import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Container from "layout/Container"
import Message from "elements/Message"
import ActionButtons from "elements/ActionButtons"

import AssetsSection from "./FinancialAssessmentSummary/AssetsSection"
import UploadDocumentsSection from "./FinancialAssessmentSummary/UploadDocumentsSection"
import IncomeInformationSection from "./FinancialAssessmentSummary/IncomeInformationSection"
import PersonalInformationSection from "./FinancialAssessmentSummary/PersonalDetailsSection"
import AlternativeFundingSupportSection from "./FinancialAssessmentSummary/AlternativeFundingSupportSection"
import MonthlyExpenseAndLiabilitiesSection from "./FinancialAssessmentSummary/MonthlyExpenseAndLiabilitiesSection"

import { AppContext } from "../../context/AppContext"
import { RESET_STATE } from "../../context/AppReducer"
import { consentAndAuthValidation } from "./utils/financialAssessmentValidation"
import { handleFinancialAssessmentSubmission } from "./services/financialAssessment"
import ConsentAndAuthorization from "./FinancialAssessmentSummary/ConsentAndAuthorization"

import {
  GATSBY_FINANCIAL_ASSESSMENT_KEY,
  GATSBY_FINANCIAL_ASSESSMENT_VALUE,
} from "gatsby-env-variables"

const SummaryPage = () => {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  let patientData = state.patientDetails
  let financialDetails = state.financialDetails
  let documentUploads = state.documents

  const handleSubmitError = ({ message }) => {
    setErrorMessage(message)
    setLoading(false)
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_FINANCIAL_ASSESSMENT_KEY) !==
      GATSBY_FINANCIAL_ASSESSMENT_VALUE
    ) {
      navigate("/financial-assessment/")
    }
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    handleFinancialAssessmentSubmission({
      patientDetails: patientData,
      financialDetails: financialDetails,
      documentUploads: documentUploads,
      callback: () => {
        dispatch({
          type: RESET_STATE,
        })
        navigate("/financial-assessment/complete")
      },
      errorCallback: handleSubmitError,
    })
  }

  return (
    <Layout
      title="Financial Assessment Summary"
      subtitle="Please review if the following information is correct. Your information will be used as a basis for the financial assessment."
    >
      <SEO title="Financial Assessment" />
      <Container isCentered>
        <Formik
          onSubmit={handleSubmit}
          initialValues={state}
          validationSchema={consentAndAuthValidation}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              <PersonalInformationSection data={patientData} />
              <IncomeInformationSection data={financialDetails} />
              <AlternativeFundingSupportSection data={financialDetails} />
              <AssetsSection data={financialDetails} />
              <MonthlyExpenseAndLiabilitiesSection data={financialDetails} />
              <UploadDocumentsSection />
              <ConsentAndAuthorization values={values} />
              {errorMessage && <Message color="danger">{errorMessage}</Message>}
              <ActionButtons
                back={{ label: "Back", link: "/financial-assessment/upload" }}
                submit={{ label: "Submit", loading: loading, disbled: loading }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default SummaryPage
