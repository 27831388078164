import React from "react"
import classNames from "classnames"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import styles from "../utils/financialAssessment.module.scss"

const AlternativeFundingSupportSection = ({ data }) => {
  let publicFunding = `${
    data.publicAmountPhilHealth
      ? `PhilHealth: ${data.publicAmountPhilHealth}, `
      : ""
  }${data.publicAmountDSWD ? `DSWD: ${data.publicAmountDSWD}, ` : ""}${
    data.publicAmountDOH ? `DOH: ${data.publicAmountDOH}, ` : ""
  }${data.publicAmountPAGCOR ? `PAGCOR: ${data.publicAmountPAGCOR}, ` : ""}${
    data.publicAmountPCSO ? `PCSO: ${data.publicAmountPCSO}, ` : ""
  }${data.publicAmountOWWA ? `OWWA: ${data.publicAmountOWWA}, ` : ""}${
    data.publicAmountOfficeOfThePresident
      ? `Office of the President: ${data.publicAmountOfficeOfThePresident}, `
      : ""
  }${
    data.publicAmountOfficeOfTheMayorRepresentative
      ? `Office of the Mayor Representative: ${data.publicAmountOfficeOfTheMayorRepresentative}, `
      : ""
  }${
    data.publicAmountSSSGSISLoan
      ? `PhilHealth: ${data.publicAmountSSSGSISLoan}, `
      : ""
  }${
    data.publicAmountOfficeOfTheVicePresident
      ? `Office of the Vice President: ${data.publicAmountOfficeOfTheVicePresident}, `
      : ""
  }${
    data.publicAmountOtherName
      ? `${data.publicAmountOtherName}: ${data.publicAmountOtherAmount}, `
      : ""
  }`

  let privateFunding = `${
    data.privateAmountCompanyHealthBenefit
      ? `Company Health Benefit: ${data.privateAmountCompanyHealthBenefit}, `
      : ""
  }${
    data.privateAmountPersonalHMO
      ? `Personal HMO: ${data.privateAmountPersonalHMO}, `
      : ""
  }${
    data.privateAmountPersonalInsurance
      ? `Personal insurance: ${data.privateAmountPersonalInsurance}, `
      : ""
  }${
    data.privateAmountFoundationsNGOs
      ? `Foundations or NGOs: ${data.privateAmountFoundationsNGOs}, `
      : ""
  }${
    data.privateAmountPersonalBankLoansCredits
      ? `Personal bank loans or credits: ${data.privateAmountPersonalBankLoansCredits}, `
      : ""
  }${
    data.privateAmountOtherName
      ? `${data.privateAmountOtherName}: ${data.privateAmountOtherAmount}, `
      : ""
  }`

  return (
    <Section
      title="Alternative Funding Support or Financial Assistance Availed"
      isRequired
      addOns={{
        right: (
          <EditDetailsButton route="/financial-assessment/financial-details#funding-support" />
        ),
      }}
    >
      <div class="table-container">
        <table class="table is-fullwidth is-size-5">
          <tbody className={classNames("body")}>
            <tr>
              <td className="has-text-weight-bold">
                Public or government funding sources
              </td>
              <td className={classNames(styles["summary__tableData"])}>
                {publicFunding !== ""
                  ? publicFunding.replace(/,\s*$/, "")
                  : "None"}
              </td>
            </tr>
            <tr>
              <td className="has-text-weight-bold">Private funding sources</td>
              <td className={classNames(styles["summary__tableData"])}>
                {privateFunding !== ""
                  ? privateFunding.replace(/,\s*$/, "")
                  : "None"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default AlternativeFundingSupportSection
