import { GATSBY_ENV } from "gatsby-env-variables"
import { sendToZendesk } from "../../../services/zendeskService"

import { financialAssessmentZendeskTemplate } from "../templates/financialAssessmentTemplate"
import { handleError } from "../../../services/handleError"

import { GATSBY_FINANCIAL_ASSESSMENT_ZENDESK_ID } from "gatsby-env-variables"

export const checkFileUploads = (docTypes, state) => {
  for (let index in docTypes) {
    let documentCount = state?.documents.filter(document =>
      document.name.startsWith(docTypes[index])
    ).length
    if (documentCount === 0) return true
  }
  return false
}

export const sendFinancialDetailsFormToZendesk = config => {
  let {
    documentUploads,
    patientDetails,
    financialDetails,
    ticketTemplate,
  } = config
  let isTest = GATSBY_ENV !== "production"

  let tags = ["financial_assessment", "bm_hope"]
  if (isTest) tags.push("test")

  let subject = `${isTest ? "[TEST] " : ""} Financial Assessment Form of ${
    patientDetails?.firstName
  } ${patientDetails?.lastName}`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${patientDetails?.firstName} ${patientDetails?.lastName}`,
      email: patientDetails?.email,
    },
    custom_fields: [
      {
        id: GATSBY_FINANCIAL_ASSESSMENT_ZENDESK_ID,
        value: JSON.stringify({ patientDetails, financialDetails }),
      },
    ],
    comment: { body: ticketTemplate({ patientDetails, financialDetails }) },
  }

  return sendToZendesk(requestBody || {}, documentUploads || [])
}

export const handleFinancialAssessmentSubmission = async ({
  patientDetails,
  financialDetails,
  documentUploads,
  callback,
  errorCallback,
}) => {
  try {
    await sendFinancialDetailsFormToZendesk({
      patientDetails,
      documentUploads,
      financialDetails,
      ticketTemplate: financialAssessmentZendeskTemplate,
    })

    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
