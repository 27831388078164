import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import UploadGuidelines from "elements/UploadGuidelines"
import RequirementsMessage from "./UploadDocuments/RequirementsMessage"
import UploadDocumentDropzone from "./UploadDocuments/UploadDocumentDropzone"

import { AppContext } from "../../context/AppContext"
import requiredDocuments from "./utils/requiredDocuments.json"
import { checkFileUploads } from "./services/financialAssessment"

import {
  GATSBY_FINANCIAL_ASSESSMENT_KEY,
  GATSBY_FINANCIAL_ASSESSMENT_VALUE,
} from "gatsby-env-variables"

const UploadDocuments = () => {
  const { state } = useContext(AppContext)

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_FINANCIAL_ASSESSMENT_KEY) !==
      GATSBY_FINANCIAL_ASSESSMENT_VALUE
    ) {
      navigate("/financial-assessment/")
    }
  }, [])

  return (
    <Layout
      title="Upload Required Documents"
      subtitle="Please upload the required documents."
      seoTitle="Upload Required Documents"
    >
      <Container isCentered>
        <div className="mb-3">
          <UploadGuidelines guideline="financial_assessment_guidelines" />
        </div>
        <Section title="Valid IDs" isRequired>
          <RequirementsMessage
            header="Please upload any two (2) of the following valid IDs:"
            requirements={requiredDocuments.validIDs}
          />
          <div className="columns">
            <div className="column">
              <UploadDocumentDropzone
                docType="id1"
                label="Upload Valid ID 1"
                icon="id"
                maxFiles={1}
              />
            </div>
            <div className="column">
              <UploadDocumentDropzone
                docType="id2"
                label="Upload Valid ID 2"
                icon="id"
                maxFiles={1}
              />
            </div>
          </div>
        </Section>
        <Section title="Proof of Income" isRequired>
          <RequirementsMessage
            header="Please upload the following documents:"
            requirements={requiredDocuments.proofOfIncome}
          />
          <UploadDocumentDropzone
            label="Take photo or choose file"
            docType="poi"
            icon="document"
            maxFiles={4}
          />
        </Section>
        <Section title="Utility Bills" isRequired>
          <RequirementsMessage
            header="Please upload the following documents:"
            requirements={requiredDocuments.utilityBills}
          />
          <div className="columns">
            <div className="column">
              <UploadDocumentDropzone
                label="Upload Water Bill"
                docType="water_bill"
                icon="document"
                maxFiles={3}
              />
            </div>
            <div className="column">
              <UploadDocumentDropzone
                label="Upload Electric Bill"
                docType="electric_bill"
                icon="document"
                maxFiles={3}
              />
            </div>
          </div>
        </Section>
        <Section title="Supporting Documents">
          <RequirementsMessage
            header="Please upload the following documents:"
            requirements={requiredDocuments.supportingDocuments}
          />
          <UploadDocumentDropzone
            label="Take photo or choose file"
            docType="docs"
            icon="document"
            maxFiles={4}
          />
        </Section>
        <ActionButtons
          next={{
            label: "Next",
            link: "/financial-assessment/summary",
            disabled: checkFileUploads(
              ["id1", "id2", "poi", "water_bill", "electric_bill"],
              state
            ),
          }}
          back={{
            label: "Back",
            link: "/financial-assessment/financial-details",
          }}
        />
      </Container>
    </Layout>
  )
}

export default UploadDocuments
