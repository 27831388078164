import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import styles from "../utils/financialAssessment.module.scss"

const PersonalInformationSection = ({ data }) => {
  let {
    firstName,
    lastName,
    mobileNumber,
    deliveryAddress,
    yearsOfResidency,
    civilStatus,
    numberOfDependents,
  } = data

  let { streetAddress, city } = deliveryAddress

  let checklist = [
    firstName,
    lastName,
    mobileNumber,
    streetAddress,
    city.value,
    yearsOfResidency,
    civilStatus.value,
    numberOfDependents,
  ]

  let hasValue = true
  for (let condition in checklist) {
    let parsedValue = parseInt(checklist[condition])
    if (!checklist[condition] && parsedValue < 0) hasValue = false
  }

  return (
    <Section
      title="Patient Details"
      isRequired
      addOns={{
        right: (
          <EditDetailsButton route="/financial-assessment/patient-details" />
        ),
      }}
    >
      {!hasValue && (
        <Message color="warning">
          <p className="has-text-black is-size-6">No patient details found.</p>
        </Message>
      )}
      {hasValue && (
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              <tr>
                <td>Name</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.firstName} {data.lastName}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.email}
                </td>
              </tr>
              <tr>
                <td>Mobile</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.mobileNumber}
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.deliveryAddress.streetAddress},{" "}
                  {data.deliveryAddress.city.value},{" "}
                  {data.deliveryAddress.province.value}
                </td>
              </tr>
              <tr>
                <td>Years of Residency</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.yearsOfResidency}
                </td>
              </tr>
              <tr>
                <td>Civil Status</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.civilStatus.value}
                </td>
              </tr>
              <tr>
                <td>Number of Dependents</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.numberOfDependents}
                </td>
              </tr>
              <tr>
                <td>PhilHealth Number</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.philHealthNumber || "N/A"}
                </td>
              </tr>
              <tr>
                <td>TIN Number</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.sssGSISNumber || "N/A"}
                </td>
              </tr>
              <tr>
                <td>SSS or GSIS Number</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {data.tinNumber || "N/A"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}

export default PersonalInformationSection
