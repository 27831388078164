export const MONEY_PLACEHOLDER = "123.00"

export const incomeInformationFollowUpQuestions = [
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Patient's monthly income",
    placeholder: MONEY_PLACEHOLDER,
    name: "patientMonthlyIncome",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "salaryOrWage",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Household’s monthly income",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyBusinessIncome",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "business",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly income",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyIncome",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "practiceOfProfession",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly average",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyAverage",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "commissions",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly income",
    placeholder: MONEY_PLACEHOLDER,
    name: "propertyMonthlyIncome",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "farms,Haciendas,OrFishPonds",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly income",
    placeholder: MONEY_PLACEHOLDER,
    name: "estateMonthlyIncome",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "realEstate,House,OrRoomRentals",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly average",
    placeholder: MONEY_PLACEHOLDER,
    name: "investmentMonthlyAverage",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "earningsFromInvestments",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Source of pension",
    placeholder: "Source of pension",
    name: "sourceOfPension",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "pensions",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly income",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyPension",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "pensions",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Source of remittance",
    placeholder: "Source of remittance",
    name: "sourceOfRemittance",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "remittance",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly remittance",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyRemittance",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "remittance",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Source of support",
    placeholder: "Father",
    name: "sourceOfSupport",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "familySupport",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly support",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlySupport",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "familySupport",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Other source of income",
    placeholder: "Freelancing",
    name: "otherSource",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "other(PleaseSpecify)",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyAmount",
    referenceQuestion: "sourceOfIncome",
    referenceAnswer: "other(PleaseSpecify)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
]

export const housingResidenceStatusFollowupQuestions = [
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly amortization",
    placeholder: MONEY_PLACEHOLDER,
    name: "mortgagedMonthlyAmortization",
    referenceQuestion: "typeOfResidence",
    referenceAnswer: "owned,Mortgaged",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly rental",
    placeholder: MONEY_PLACEHOLDER,
    name: "monthlyRental",
    referenceQuestion: "typeOfResidence",
    referenceAnswer: "rented",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Please indicate your residence type",
    placeholder: "Please indicate your residence type",
    name: "otherResidenceStatus",
    referenceQuestion: "typeOfResidence",
    referenceAnswer: "other(pleaseSpecify)",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Monthly amount paid (Optional)",
    placeholder: MONEY_PLACEHOLDER,
    name: "otherResidenceStatusMonthly",
    referenceQuestion: "typeOfResidence",
    referenceAnswer: "other(pleaseSpecify)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
]

export const hasVehicleFollowupQuestions = [
  {
    type: "number",
    initialValue: "",
    isRequired: true,
    label: "How many vehicles do you own?",
    placeholder: "1",
    name: "numberOfVehiclesOwned",
    referenceQuestion: "hasVehicle",
    referenceAnswer: "yes",
    isFollowUpQuestion: true,
    min: "1",
    max: 10,
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "What kinds of vehicles do you own? (e.g. Honda Jazz)",
    placeholder: "Honda Jazz",
    name: "kindsOfVehicleOwned",
    referenceQuestion: "hasVehicle",
    referenceAnswer: "yes",
    isFollowUpQuestion: true,
  },
]

export const publicAlternativeFundingSupportFollowupQuestions = [
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountPhilHealth",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "philHealth",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountDSWD",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "departmentOfSocialWelfareAndDevelopment(DSWD)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountDOH",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "departmentOfHealth(DOH)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountPAGCOR",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "philippineAmusementAndGamingCorporation(PAGCOR)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountPCSO",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "philippineCharitySweepstakesOffice(PCSO)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountOWWA",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "overseasWorkersWelfareAdministration(OWWA)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountOfficeOfThePresident",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "officeOfThePresident",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountOfficeOfTheMayorRepresentative",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "officeOfTheMayorOrCongressionalRepresentative",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountSSSGSISLoan",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "sSSOrGSISLoan",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Other public or government funding sources",
    placeholder: "Other public or government funding sources",
    name: "publicAmountOtherName",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "other(pleaseSpecify)",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountOtherAmount",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "other(pleaseSpecify)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "publicAmountOfficeOfTheVicePresident",
    referenceQuestion: "publicFundingSupport",
    referenceAnswer: "officeOfTheVicePresident",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "9",
  },
]

export const privateFundingSupportAmountsFollowupQuestions = [
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "privateAmountCompanyHealthBenefit",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "companyHealthBenefit",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Please specify your HMO provider",
    placeholder: "Please specify your HMO provider",
    name: "privatePersonalHMOProvider",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "personalHMO",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "privateAmountPersonalHMO",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "personalHMO",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Please specify your insurance provider",
    placeholder: "Please specify your insurance provider",
    name: "privatePersonalInsuranceProvider",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "personalInsurance",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "privateAmountPersonalInsurance",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "personalInsurance",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Please specify the foundation or NGO",
    placeholder: "Please specify the foundation or NGO",
    name: "privateFoundationsNGOsProvider",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "foundationsOrNGOs",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "privateAmountFoundationsNGOs",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "foundationsOrNGOs",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "privateAmountPersonalBankLoansCredits",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "personalBankLoansOrCredits",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
  {
    type: "text",
    initialValue: "",
    isRequired: true,
    label: "Other private funding sources",
    placeholder: "Other private funding sources",
    name: "privateAmountOtherName",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "other(pleaseSpecify)",
    isFollowUpQuestion: true,
  },
  {
    type: "text",
    isNumeric: true,
    initialValue: "",
    isRequired: true,
    label: "Amount",
    placeholder: MONEY_PLACEHOLDER,
    name: "privateAmountOtherAmount",
    referenceQuestion: "privateFundingSupport",
    referenceAnswer: "other(pleaseSpecify)",
    isFollowUpQuestion: true,
    min: "0",
    maxLength: "11",
  },
]
