import React from "react"
import classNames from "classnames"
import { Link, navigate } from "gatsby"
import { GATSBY_BASE_URL } from "gatsby-env-variables"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Container from "layout/Container"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"

import styles from "./utils/financialAssessment.module.scss"
import { importantInstructions } from "./utils/financialAssessmentSplashPageContent"

import {
  GATSBY_FINANCIAL_ASSESSMENT_KEY,
  GATSBY_FINANCIAL_ASSESSMENT_VALUE,
} from "gatsby-env-variables"

const FinancialAssessment = () => {
  const handleSubmit = () => {
    sessionStorage.setItem(
      GATSBY_FINANCIAL_ASSESSMENT_KEY,
      GATSBY_FINANCIAL_ASSESSMENT_VALUE
    )
    navigate("/financial-assessment/patient-details")
  }

  return (
    <Layout title={<span>Financial Assessment Mechanics</span>}>
      <SEO title="Financial Assessment Mechanics" />
      <Container isCentered customClassName="content">
        <p>
          To receive assistance, you must be enrolled in the program and
          prescribed with the medicines covered in the program. If you have not
          yet enrolled, please fill out the enrollment form at
          <br className="is-hidden-desktop" />{" "}
          <Link to="/patient/enroll" className="has-text-weight-bold">
            {GATSBY_BASE_URL}patient/enroll
          </Link>
          .
        </p>
        <h4 className="is-size-4">Important Notes</h4>
        <p>
          <ol className="is-size-5">
            {importantInstructions.map(data => (
              <li
                className={classNames(
                  styles["li__data"],
                  styles["instructions__list"]
                )}
              >
                {data}
              </li>
            ))}
          </ol>
        </p>
        <h4 className="is-size-4">Instructions</h4>
        <ol className="is-size-5">
          <li className={classNames(styles["li__data"])}>
            The form must be filled out by you or your authorized
            representative.
          </li>
          <li className={classNames(styles["li__data"])}>
            <div>
              Answer all questions and fill out all items. Do not leave any item
              blank.
            </div>
            <ol className={classNames("ml-2", styles["instructions__list"])}>
              <li className={classNames(styles["li__data"])}>
                If the question requires a numerical response and the answer is
                “none”, write 0.
              </li>
              <li className={classNames(styles["li__data"])}>
                If the question is not applicable to your situation, write NA.
              </li>
              <li className={classNames(styles["li__data"])}>
                All monetary values should be in Philippine Peso (Php).
              </li>
            </ol>
          </li>
        </ol>
        <ConsentAndAuthorization
          cta="Continue"
          ctaClassNames="is-fullwidth"
          handleOnSubmit={handleSubmit}
        ></ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default FinancialAssessment
